/*
 * Licensed to the Apache Software Foundation (ASF) under one or more contributor
 * license agreements; and to You under the Apache License, Version 2.0. "
 */
/*
 * Written by Scopevisio AG 2021
 */
import "./eudgc"
import "./cose1"

console.log("EUDGC by Scopevisio AG")